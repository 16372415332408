import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/blog/seo';
import Layout from '../containers/layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from '../components/layout/LandingPage/LandingPage2';
import { getLocaleWiseLabel as t } from '../lib/localeUtils';
import { Button } from 'react-bootstrap';
import { navigate } from 'gatsby';
import clientConfig from '../../client-config';

console.log('version 1.1');

export const query = graphql`
  query IndexPageQuery {
    pageLabels_home: sanityPage(pageName: { eq: "Home" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    pageLables_landing: sanityPage(pageName: { eq: "LandingPageMain" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    pageLables_header: sanityPage(pageName: { eq: "Header" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }

    pageLables_footer: sanityPage(pageName: { eq: "Footer" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;
const IndexPage = (props: any) => {
  console.log('in index');
  console.log(clientConfig.backend);
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  function handleClickEvent(e: Event) {
    e.preventDefault();
    let btnId = (e.target as HTMLButtonElement).id;
    sessionStorage.setItem('btnId', btnId);
    navigate('/LoanPage', { replace: true });
  }

  const site = (data || {}).site;
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  const labels = [...data.pageLables_header.labels, ...data.pageLables_footer.labels];

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <LandingPage labelArr={labels} />
    </Layout>
  );
};

export default IndexPage;
