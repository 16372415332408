// cSpell:disable
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';

import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';
import { Link, navigate } from 'gatsby';
import styles from './LandingPage2.module.css';
import Axios from 'axios';
import clientConfig from '../../../../client-config';
import { useForm } from 'react-hook-form';
import Dropdown from './dropdown';
import DropdownMobile from '../header/Dropdown/mobile';
import LoginButton from '../header/LoginButton';
import { sendGetInTouchMessage } from '../../../lib/getInTouchMessageHandler';
const logo = require('../../../../static/assets/images/tab_header_icon_black_117x117.png');

type AppProps = {
  labelArr: Array<object>;
};
const footerLinks = [
  'הצהרת נגישות',
  'הלוואות לחברות',
  'הלוואות למשרדים',
  'הלוואות הקמה',
  'הלוואות גישור',
  'מימון הון חזור',
  'מימון מלאי',
  'מימון ציוד',
  'מימון ייבוא',
  'מימון לעסק',
];
const LandingPage = ({ labelArr }: AppProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [pathname, setPathname] = useState('');
  const { register, reset, handleSubmit } = useForm<{ name: string; mail: string; content: string }>();
  const [submittingContactForm, setSubmittingContactForm] = React.useState(false);
  const [isContactFormFailed, setIsContactFormFailed] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showOther, setShowOther] = React.useState(false);
  useEffect(() => {
    const handlePathname = () => {
      setPathname(window.location.pathname);
      console.log('header');
    };
    handlePathname();
  });
  const onSubmit = async (data: { name: string; mail: string; content: string }) => {
    if (data.name != '' && data.mail != '' && data.content != '') {
      setIsContactFormFailed(false);
      setSubmittingContactForm(true);
      void sendGetInTouchMessage(data.name, data.mail, data.content)
        .then((response) => {
          console.log('response', response);
          reset();
          setSubmittingContactForm(false);
          setShowOther(!showOther);
        })
        .catch((error) => {
          setSubmittingContactForm(false);
          setIsContactFormFailed(true);
          console.log(error);
        });
    } else {
      setShow(!show);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseOther = () => {
    setShowOther(false);
  };

  var handleClickEvent = (e: any) => {
    navigate('/LoanPage', { state: { dropDownDefaults: { btnClicked: e.target.id } } });
  };
  let getModals = () => (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
          <br />
          <h6 className='text-right' id='alertText'>
            אנא מלא את כל השדות
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showOther} onHide={handleCloseOther}>
        <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
          <br />
          <h6 className='text-right' id='alertText'>
            תודה על הפנייה, כבר נחזור אלייך
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseOther}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  const companyHeaderDropdown = [
    { title: t(labelArr, 'dropdown_company_about_button', 'heb'), link: '/CompanyPage/#about' },
    { title: t(labelArr, 'dropdown_company_team_button', 'heb'), link: '/CompanyPage/#team' },
    {
      title: t(labelArr, 'dropdown_company_compliance_button', 'heb'),
      link: '/CompanyPage/#comp',
    },
    {
      title: t(labelArr, 'dropdown_company_partners_button', 'heb'),
      link: '/CompanyPage/#partners',
    },
  ];
  const financeHeaderDropdown = [
    {
      title: t(labelArr, 'dropdown_Finance_process_button', 'heb'),
      link: '/FinanceProcess/#financeProcess',
    },
    {
      title: t(labelArr, 'dropdown_Habankaim_adv_button', 'heb'),
      link: '/FinanceProcess/#habankaimAdv',
    },
    { title: t(labelArr, 'dropdown_shotterm_button', 'heb'), link: '/short_term_info' },
    { title: t(labelArr, 'dropdown_longterm_button', 'heb'), link: '/long_term_info' },
    { title: t(labelArr, 'dropdown_discounting_button', 'heb'), link: '/discounting_info' },
    { title: t(labelArr, 'dropdown_spcecial_finance_button', 'heb'), link: '/' },
  ];
  const getSideNav = () => (
    <>
      {menuOpen && (
        <div className={styles.side_bar}>
          <div className={''}>
            <DropdownMobile
              title={t(labelArr, 'sme_finance_header_button_txt', 'heb')}
              active={pathname === '/SmeFinance'}
              mainLink='/SmeFinance'
              list={financeHeaderDropdown}
            />
          </div>
          <div className={''}>
            <DropdownMobile
              title={t(labelArr, 'the_company_header_button_txt', 'heb')}
              active={pathname === '/CompanyPage'}
              mainLink='/CompanyPage'
              list={companyHeaderDropdown}
            />
          </div>
          <div className={styles.link}>
            <Link to='/blog' className={[styles.link, pathname === '/blog' ? styles.button_active : ''].join(' ')}>
              {t(labelArr, 'Blog_and_content_header_button_txt', 'heb')}
            </Link>
          </div>
          <div className={styles.link}>
            <Link
              to='/get_in_touch'
              className={[styles.link, pathname === '/get_in_touch' ? styles.button_active : ''].join(' ')}
            >
              {t(labelArr, 'get_in_touch_header_button_txt', 'heb')}
            </Link>
          </div>
          <div className={styles.link}>
            <LoginButton labels={labelArr} />
          </div>
        </div>
      )}
    </>
  );
  return (
    <>
      <div className={styles.containerCenter}>
        <div className={styles.homePageScreenSmall}>
          <img
            className={styles.homePageScreenSmallCoverOne}
            src='assets/newDesignImages/brooke-cagle-norsyxmhgpi-unsplash-2@1x.webp'
          ></img>
          <img
            className={styles.homePageScreenSmallCoverTwo}
            src='assets/newDesignImages/brooke-cagle-norsyxmhgpi-unsplash-3@1x.webp'
          ></img>
          <img
            className={styles.homePageScreenSmallCoverThree}
            src='assets/newDesignImages/brooke-cagle-norsyxmhgpi-unsplash-4@1x.webp'
          />
          <div className={styles.homePageScreenSmallCover}></div>
          <div className={styles.homePageScreenSmallCoverText}>
            בנקאות מימון
            <br />
            בלי בנק
          </div>
          <div className={styles.homePageScreenSmallCoverTextSub}>
            תשכחו מכל מה שידעתם על מימון עסקים.
            <br />
            אנחנו לא בנק ולא חברת ניכיון צ'קים
            <br />
            אנחנו מה שמימון בנקאי יכול להיות
          </div>
          <div onClick={(e: any) => handleClickEvent(e)}>
            <div className={styles.homePageScreenSmallCoverButton}></div>
            <div className={styles.homePageScreenSmallCoverButtonText}>לקבלת מימון</div>
            <div className={styles.homePageScreenSmallCoverButtonArrow}>
              <img
                className={styles.homePageScreenSmallCoverButtonArrowLine}
                src='assets/newDesignImages/line-67-1@1x.png'
              />
              <img
                className={styles.homePageScreenSmallCoverButtonArrowPath}
                src='assets/newDesignImages/path-60-1@1x.png'
              />
            </div>
          </div>
          <div className={styles.homePageAbout}></div>
          <div className={styles.homePageAboutHeading}>מימון עסקים קטנים ובינוניים</div>
          <div className={styles.homePageAboutHeadingSubOne}>
            מבחינתנו כל העסקים הם גדולים וצריכים לקבל שירות איכותי.
            <br />
            אבל מבחינת המשק אם המחזור העסקי שלך נמוך מ-30 מליון אתה קטן
          </div>
          <p className={styles.homePageAboutHeadingSubTwo}>
            מבחינתנו כל העסקים הם גדולים וצריכים לקבל שירות
            <br />
            איכותי. אבל מבחינת המשק אם המחזור העסקי שלך
            <br />
            נמוך מ-30 מליון אתה קטן
          </p>
          <div className={styles.aboutOneHeading}>האפליה באשראי</div>
          <p className={styles.aboutOneText}>
            למרות התרומה למשק עסקים קטנים ובינוניים
            <br />
            משלמים הכי הרבה על האשראי, יותר מכל מגזר
            <br />
            אחר. בנוסף הם מקבלים רק עשירית מהאשראי
            <br />
            (שזה חמישית ממה שמגיע להם) ומעמידים הכי
            <br />
            הרבה בטחונות בשביל לקבל אותו.
          </p>
          <div className={styles.aboutTwoHeading}>התרומה למשק</div>
          <p className={styles.aboutTwoText}>
            עסקים קטנים ובינוניים מהווים 99% מהעסקים
            <br />
            בישראל. הם גם אחראים על הכי הרבה מהתוצר
            <br />
            ומעסיקים הכי הרבה מועסקים
          </p>
          <div className={styles.aboutThreeHeading}>זו לא הגדרה שאנחנו בחרנו...</div>
          <p className={styles.aboutThreeText}>
            אבל המשק הישראלי (והבנקים) קבעו
            <br />
            שעסקים שהמחזור שלהם הוא בין 0 ל-60
            <br />
            מליון הם קבוצה אחת ונותנים להם יחס זהה.
            <br />
            והיחס איך לומר בעדינות הוא לא מדהים.
          </p>
          <div className={styles.aboutFourHeading}>
            אולטרה,
            <br />
            מה שמימון בנקאי יכול להיות
          </div>
          <div className={styles.aboutFourTextOne}>
            אז נעים להכיר. אנחנו אולטרה פיננסים. עם רישיון מתן אשראי
            <br />
            מורחב, פלטפורמה טכנולוגית, תפיסות אשראי מתקדמות
            <br />
            וכסף. כסף שאנחנו רוצים להלוות לעסקים שיבחרו
            <br />
            בנו להיות בנקאי המימון שלהם.
          </div>
          <p className={styles.aboutFourTextTwo}>
            אז נעים להכיר. אנחנו אולטרה. עם רישיון מתן
            <br />
            אשראי מורחב, פלטפורמה טכנולוגית, תפיסות
            <br />
            אשראי מתקדמות וכסף. כסף שאנחנו רוצים
            <br />
            להלוות לעסקים שיבחרו בנו.
            <br />
            להיות בנקאי המימון שלהם
          </p>
          <img className={styles.aboutFourLine} src='assets/newDesignImages/line-40-1@1x.png' />
          <div className={styles.aboutFiveHeading}>איך שזה היה צריך להיות</div>
          <p className={styles.aboutFiveText}>
            דמיין רגע שוק תחרותי, שבו הרבה שחקני מימון
            <br />
            מתחרים לתת שירות לעסק שלך. ואתה תבחר
            <br />
            את מי שנותן את השירות הכי טוב, שמבין אותך,
            <br />
            שהמוצר שלו הופך את העסק שלך לטוב יותר.
          </p>
          <div className={styles.aboutSixHeading}>מימון רע מסוכן לעסקים</div>
          <p className={styles.aboutSixText}>
            אחד מעשרה עסקים נסגר רק בגלל היעדר מימון.
            <br />
            60% מהעסקים מעידים שהם צריכים אשראי
            <br />
            נוסף ומעט מאוד עסקים יודעים מה באמת הם
            <br />
            משלמים על המימון שלהם מרוב יועצים, בנקים
            <br />
            ועיסוק מתיש מול גופים אטומים ומיושנים.
          </p>
          <div className={styles.cardCoverBlock}></div>
          <img
            className={styles.cardCoverSmallScreen}
            src='assets/newDesignImages/campaign-creators-qci-mzvodou-unsplash-1@1x.webp'
          ></img>
          <img className={styles.cardCoverSmallScreenFooter} src='assets/newDesignImages/line-35-1@1x.png' />
          <img
            className={styles.cardCoverMobile}
            src='assets/newDesignImages/campaign-creators-qci-mzvodou-unsplash-2@1x.webp'
          ></img>
          <img className={styles.cardCoverMobileFooter} src='assets/newDesignImages/line-66@1x.png'></img>
          <div className={styles.cardCoverHeading}>בחרו את ההלוואה המתאימה עבורכם</div>
          <div className={styles.cardCoverHeadingMobile}>
            בחרו את ההלוואה
            <br />
            המתאימה עבורכם
          </div>
          <div className={styles.cardOne}></div>
          <div className={styles.cardOneHeading}>מימון חשבוניות</div>
          <p className={styles.cardOneText}>
            הקדמת תשלום עתידי מלקוח
            <br />
            המובטח באמצעות צ’ק או
            <br />
            חשבונית מאושרת. עד 120 יום
            <br />
            הקדמה.
          </p>
          <div id='fundingInvoicesOrChecks' onClick={(e: any) => handleClickEvent(e)}>
            <button className={styles.cardOneButton}></button>
            <div className={styles.cardButtonText}>מימון לחשבונית / צ׳ק</div>
          </div>
          <div className={styles.cardTwo}>
            <div className={styles.cardTwoBody}></div>
            <div className={styles.cardTwoHeading}>הלוואות ארוכות</div>
            <p className={styles.cardTwoText}>
              הלוואות לתקופה ארוכה משנה למטרות
              <br />
              צמיחה, רכישת ציוד, השקעה וכדומה.
              <br />
              תשלומים שווים של קרן וריבית לאורך
              <br />
              תקופת ההלוואה.
            </p>
            <div id='mediumOrLongTermLoan' onClick={(e: any) => handleClickEvent(e)}>
              <button className={styles.cardTwoButton}></button>
              <div className={styles.cardTwoButtonText}>הלוואה ארוכה</div>
            </div>
          </div>
          <div className={styles.cardThree}>
            <div className={styles.cardThreeBody}></div>
            <div className={styles.cardThreeHeading}>הלוואות קצרות</div>
            <p className={styles.cardThreeText}>
              מימון לתקופה הקצרה מ-12 חודשים
              <br />
              למטרות ייבוא, הון חוזר, גישור תזרימי
              <br />
              וכיוצא באלה. תשלום ריבית באופן
              <br />
              חודשי והקרן בסוף תקופה.
            </p>
            <div
              className={styles.cardThreeButtonWrap}
              id='bridgingOrShortTermLoans'
              onClick={(e: any) => handleClickEvent(e)}
            >
              <button className={styles.cardThreeButton}></button>
              <div className={styles.cardThreeButtonText}>הלוואה קצרה</div>
            </div>
          </div>
          <div className={styles.whatWeDoWrap}></div>
          <div className={styles.whatWeDo}>מה אנחנו עושים</div>
          <div className={styles.chatIconWrap}>
            <div className={styles.chatIconBg}></div>
            <img className={styles.chatIcon} src='assets/newDesignImages/message-circle-2@1x.png' />
          </div>
          <div className={styles.chatHeading}>מלווים</div>
          <p className={styles.chatText}>
            מספקים את השירות הבנקאי מימוני והכסף שיאפשר
            <br />
            לעסק שלך לעבוד. לומדים את הצרכים והאופי של
            <br />
            העסק, מתאימים את מוצרי האשראי הנכונים ומספרים
            <br />
            אותם. עושים את זה בצורה מקצועית ושירותית,
            <br />
            במחירים הוגנים ובנוחות.
          </p>
          <div className={styles.barIconWrap}>
            <div className={styles.barIconBg}></div>
            <div className={styles.barIconLinesWrap}>
              <img className={styles.barIconLineOne} src='assets/newDesignImages/line-36-2@1x.png' />
              <img className={styles.barIconLineTwo} src='assets/newDesignImages/line-37-2@1x.png' />
              <img className={styles.barIconLineThree} src='assets/newDesignImages/line-38-2@1x.png' />
            </div>
          </div>
          <div className={styles.barIconHeading}>מתקדמים</div>
          <p className={styles.barIconText}>
            {`פיתחנו אלגוריתם חיתום היברדי, מתקדם ואדפטיבי`}
            <br />
            {`אשר משלב טכנולוגיה, תובנות פיננסיות וגורמים`}
            <br />
            {`אנושיים. אנחנו בעיקר מבינים שבשנת ${new Date().getFullYear()} אין סיבה`}
            <br />
            {`לטרטר אותך סתם לסניף או לבקש ממך לשלוח פקס.`}
          </p>
          <div className={styles.shieldIconWrap}>
            <div className={styles.shieldIconBg}></div>
            <img className={styles.shieldIcon} src='assets/newDesignImages/shield-2@1x.png' />
          </div>
          <div className={styles.shieldIconHeading}>מפוקחים</div>
          <p className={styles.sheildIconText}>
            חברה ישראלית פרטית בעלת רישיון מתן אשראי מורחב
            <br />
            לפי חוק הפיקוח על שירותים פיננסים מוסדרים.
            <br />
            החברה הייתה מהראשונות שקיבלו רישיון קבוע על פי
            <br />
            החוק החדש.
          </p>
          <div className={styles.benefitsHeading}>יתרונות אולטרה</div>
          <div className={styles.benefitsText}>
            בסוף, הכי כדאי לעבוד עם אולטרה.
            <br />
            הכדאיות נמדדת ביותר מהריבית השנתית
            <br />
            אלא בכל ההליך שקשור להלוואה.
          </div>
          <div className={styles.benefitsCardOne}></div>
          <div className={styles.benefitsCardOneHead}>מוכוונים צמיחה ועתיד</div>
          <p className={styles.benefitsCardOneText}>
            העסק שלך גדל, מתנהל בהווה ומוכוון לעתיד, איך יכול
            <br />
            להתממן בהתאם לדו״ח השנתי מלפני שנתיים?
            <br />
            באולטרה מממנים אותך בהתאם למצב הקיים ומבינים
            <br />
            שאת תשלום ההלוואה העסק ישלם מהרווחים
            <br />
            העתידיים. כדי שאנחנו נרוויח אתה צריך להרוויח וככה
            <br />
            בנויות ההלוואות שלנו.
          </p>
          <div className={styles.benefitsCardOneIconWrap}></div>
          <div className={styles.benefitsCardOneIcon}>
            <img className={styles.benefitsCardOneIconOne} src='assets/newDesignImages/path-52-1@1x.png' />
            <img className={styles.benefitsCardOneIconTwo} src='assets/newDesignImages/path-53-1@1x.png' />
          </div>
          <div className={styles.benefitsCardTwo}></div>
          <div className={styles.benefitsCardTwoHead}>מותאם וגמיש</div>
          <p className={styles.benefitsCardTwoText}>
            העסק שלך והצרכים שלך יחודיים, למה שתקבל תנאים
            <br />
            כמו כל אחד אחר? למה לחתום על הלוואה שלא
            <br />
            מתאימה את עצמה אליך? באולטרה מבינים שבעלי
            <br />
            העסקים צריכים לקבל מוצר מותאם אישית. שפיצר,
            <br />
            בלון, בוליט, צמוד או קבוע… כל הכלים הכלכליים כדי
            <br />
            שהמימון יתאים לפעילות של העסקית והצרכים של
            <br />
            העסק.
          </p>
          <div className={styles.benefitsCardTwoIconBg}></div>
          <div className={styles.benefitsCardTwoIconWrap}>
            <div className={styles.benefitsCardTwoIconOne}></div>
            <div className={styles.benefitsCardTwoIconTwo}></div>
          </div>
          <div className={styles.benefitsCardThree}></div>
          <div className={styles.benefitsCardThreeHead}>יעילות וכלכליות</div>
          <p className={styles.benefitsCardThreeText}>
            כמה באמת עולה הלוואה בבנק? מרוב עמלות לא רואים
            <br />
            את היער. ומה עם יועץ המימון שגבה ממך אלפי שקלים
            <br />
            בנוסף להלוואה עצמה? באולטרה לא מאמינים בתיווך.
            <br />
            אנחנו מלווים את הכסף שלנו ישירות לעסקים. אנחנו
            <br />
            מדברים עם בעלי העסקים ישירות ועושים את כל
            <br />
            העבודה בעצמנו. התמחור פשוט וקולע כדי שתדע כמה
            <br />
            זה עולה לך. בסוף, זה יוצא הכי זול.
          </p>
          <div className={styles.benefitsCardThreeIconBg}></div>
          <div className={styles.benefitsCardThreeIconWrap}>
            <img className={styles.benefitsCardThreeIconOne} src='assets/newDesignImages/path-54-1@1x.png' />
            <img className={styles.benefitsCardThreeIconTwo} src='assets/newDesignImages/path-55-1@1x.png' />
            <img className={styles.benefitsCardThreeIconThree} src='assets/newDesignImages/path-55-1@1x.png' />
          </div>
          <div className={styles.benefitsCardFour}></div>
          <div className={styles.benefitsCardFourHead}>עובדים איתך ובשבילך</div>
          <p className={styles.benefitsCardFourText}>
            יש לך עסק לנהל נכון? אתה מבין מעולה איך למכור או
            <br />
            לייצר אבל יש סיבה שלא הלכת ללמוד כלכלה?
            <br />
            אולטרה באים אליך לעסק, מדברים ישירות עם הרו״ח
            <br />
            שלך ובונים לך את תוכנית המימון כדי שאתה תוכל
            <br />
            להמשיך ולנהל את העסק שלך ולא לבזבז את הזמן.
          </p>
          <div className={styles.benefitsCardFourIconBg}></div>
          <div className={styles.benefitsCardFourIconWrap}>
            <img className={styles.benefitsCardFourIconOne} src='assets/newDesignImages/path-49-1@1x.png' />
            <div className={styles.benefitsCardFourIconTwo}></div>
            <img className={styles.benefitsCardFourIconThree} src='assets/newDesignImages/path-50-1@1x.png' />
            <img className={styles.benefitsCardFourIconFour} src='assets/newDesignImages/path-51-1@1x.png' />
          </div>
          <div className={styles.contactUsWrap}>
            <img className={styles.contactUsImg} src='assets/newDesignImages/shutterstock-1107695867-1@1x.webp' />
            <div className={styles.contactUsText}>אנחנו עוסקים במתן מימון יעיל לעסקים</div>
            <div className={styles.contactUsQuote}>״</div>
          </div>
          <div className={styles.contactUsHeading}>דברו איתנו</div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={submittingContactForm}>
              <Form.Group controlId='formBasicName'>
                <Form.Label className={styles.contactNameText}>שם מלא</Form.Label>
                <Form.Control
                  className={styles.contactName}
                  type='text'
                  name='name'
                  dir='rtl'
                  ref={register}
                  placeholder='שם מלא'
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmail'>
                <Form.Label className={styles.contactEmailText}>אימייל</Form.Label>
                <Form.Control
                  className={styles.contactEmail}
                  type='email'
                  name='mail'
                  dir='rtl'
                  ref={register}
                  placeholder='אימייל'
                />
              </Form.Group>
              <Form.Group controlId='exampleForm.ControlTextarea1'>
                <Form.Label className={styles.contactReferalText}>תוכן הפנייה</Form.Label>
                <Form.Control
                  className={styles.contactReferal}
                  as='textarea'
                  name='content'
                  placeholder='תוכן הפנייה'
                  dir='rtl'
                  ref={register}
                  rows={5}
                />
              </Form.Group>
              {isContactFormFailed && (
                <div className={styles.contactUsErrorText}>אירעה שגיאה, אנא פנה אלינו בווטסאפ או אימייל</div>
              )}
              <Button type='submit' className={styles.contactUsButton}>
                {submittingContactForm ? (
                  <Spinner animation='border' />
                ) : (
                  <div className={styles.contactUsButtonText}>שליחה</div>
                )}
              </Button>
            </fieldset>
          </Form>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
